import {Component, ErrorInfo, ReactNode} from 'react'
import {Row, Col, Nav} from 'react-bootstrap'
import Image from 'next/image'
import logo from '../public/sdworx_logo.svg'
import Head from 'next/head'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <Head>
            <title>SDWorx - Oops! Something Went Wrong</title>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
          </Head>
          <main className="container-fluid">
            <Row as="header" className="c-500-header">
              <Col sm={{span: 10, offset: 1}}>
                <div className="c-500-header__logo">
                  <Image alt="logo" src={logo} />
                </div>
              </Col>
            </Row>
            <Row as="section" className="c-500-header__message-container">
              <Col sm={{offset: 2, span: 10}}>
                <div className="c-500-header__message-section-text-block">
                  <h3>Something went wrong on our side</h3>
                  <p>
                    We are sorry for the inconvenience. You can try refreshing
                    the page by clicking the button below:{' '}
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      window.location.reload()
                    }}
                    className="mb-3 btn btn-primary"
                  >
                    Refresh
                  </button>
                  <p>
                    If this did not solve the problem, please come back later
                    on. In the meantime, you can also check our social media
                    channels:
                  </p>
                </div>
              </Col>
              <Col sm={{offset: 2, span: 10}}>
                <Nav className="c-500-header__social">
                  <Nav.Link
                    key="facebook"
                    className="c-500-header__social--nav-item"
                    href="https://www.facebook.com/sdworxglobal/"
                  >
                    <span className="c-500-header__social-icon c-500-header__social-icon--facebook" />
                  </Nav.Link>
                  <Nav.Link
                    key="linkedin"
                    className="c-500-header__social--nav-item"
                    href="https://www.linkedin.com/company/sdworx/"
                  >
                    <span className="c-500-header__social-icon c-500-header__social-icon--linkedin" />
                  </Nav.Link>
                  <Nav.Link
                    key="twitter"
                    className="c-500-header__social--nav-item"
                    href="https://twitter.com/SDWorx"
                  >
                    <span className="c-500-header__social-icon c-500-header__social-icon--twitter" />
                  </Nav.Link>
                </Nav>
              </Col>
            </Row>
          </main>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
